import React from 'react'
import * as S from './style'
import ButtonCTA from '../../components/ButtonCTA/_index'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

const CashBack = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  const dataLayer: IDataLayerParams = {
    section: 'dobra_02',
    section_name: 'Inter Loop: o programa de pontos para sua empresa',
    element_action: 'click button',
    element_name: 'Conheça o Inter Loop',
    redirect_url: 'https://inter.co/empresas/programa-de-pontos-para-empresas/',
  }

  const handleClick = () => {
    sendDatalayerEvent(dataLayer)
  }

  return (
    <S.Container id='inter-loop'>
      <S.Content>

        <S.ContentImage>
          <img
            src='https://central-imagens.bancointer.com.br/images-without-small-versions/dobra2-cartaopj-loop/image.webp'
            alt='Cartão Inter Pj'
          />
        </S.ContentImage>

        <S.ContentInfo>
          <h2>Inter Loop: o programa de pontos para sua empresa</h2>
          <p>Acumule pontos com o cartão de crédito Inter Empresas e tenha a flexibilidade de trocá-los pelos benefícios que mais combinam com o seu negócio.</p>
          <ButtonCTA
            isOrange
            type='Link'
            link={dataLayer?.redirect_url as string}
            onClick={handleClick}
          >
            Conheça o Inter Loop
          </ButtonCTA>
        </S.ContentInfo>

      </S.Content>
    </S.Container>
  )
}

export default CashBack
