import React from 'react'
import * as S from './style'
import CartaoMaisProtegido from './assets/data.json'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import slugify from 'slugify'
import ButtonCTA from '../../components/ButtonCTA/_index'
import { WIDTH_MD } from 'src/styles/breakpoints'
import useWidth from 'src/hooks/window/useWidth'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

interface ICartaoMaisProtegido {
  category: string;
  price: string;
  beneficts: string[];
}

type SectionProps ={
  onOpenModal: () => void;
}

const CartaoMaisProtegidoEmpresarial = ({ onOpenModal }: SectionProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const plans: ICartaoMaisProtegido[] = CartaoMaisProtegido
  const width = useWidth()

  const dataLayer: IDataLayerParams = {
    section: 'dobra_04',
    section_name: 'Cartão Mais Protegido Empresarial',
    element_action: 'click button',
  } as IDataLayerParams

  const dataLayerMEI: IDataLayerParams = {
    ...dataLayer,
    element_name: 'Contratar Seguro cliente MEI',
    redirect_url: 'https://inter-co.onelink.me/Qyu7/vwab6v46',
  }

  const dataLayerPJ: IDataLayerParams = {
    ...dataLayer,
    element_name: 'Contratar Seguro cliente PJ',
    redirect_url: 'https://inter-co-enterprise.onelink.me/q6ee/xfw4id0j',
  }

  const dataLayerOpenModal: IDataLayerParams = {
    ...dataLayer,
    section: 'm_dobra_04',
    element_name: 'Contratar Seguro',
  }

  const handleOpenModal = () => {
    sendDatalayerEvent(dataLayerOpenModal)
    onOpenModal()
  }

  const handleClickMEI = () => {
    sendDatalayerEvent(dataLayerMEI)
  }
  const handleClickPJ = () => {
    sendDatalayerEvent(dataLayerPJ)
  }

  return (
    <S.CartaoMaisProtegidoEmpresarialSection
      id='cartao-mais-protegido-empresarial'
      className='bg-green--extra-dark'
    >
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <div>
              <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 text-white text-md-center'>
                Cartão Mais Protegido Empresarial
              </h2>
            </div>
            <p className='fs-14 lh-16 fs-lg-16 lh-lg-20 text-white text-md-center'>
              Proteja seu cartão e seu negócio contra prejuízos por perda, roubo ou operações indevidas realizadas no débito ou no crédito.
            </p>
            <S.PlansContent>
              {plans.map((plan: ICartaoMaisProtegido) => (
                <S.Card key={plan.category}>
                  <S.CardHeader>
                    <h3>{plan.category}</h3>
                    <p>{plan.price}</p>
                  </S.CardHeader>
                  <S.CardContent>
                    {plan?.beneficts?.map((text: string) => (
                      <S.CardInfo key={slugify(text)}>
                        <p dangerouslySetInnerHTML={{ __html: text }} />
                      </S.CardInfo>
                    ))}
                  </S.CardContent>
                </S.Card>
              ))}
            </S.PlansContent>
          </div>
          <div className='col-12 row'>
            {width >= WIDTH_MD ? (
              <div className='col-6 m-auto'>
                <ButtonCTA
                  isOrange
                  type='Button'
                  onClick={handleOpenModal}
                >
                  Contratar Seguro
                </ButtonCTA>
              </div>
            ) : (
              <S.BoxButton>
                <ButtonCTA
                  isOrange
                  onClick={handleClickMEI}
                  link={dataLayerMEI?.redirect_url as string}
                  type='Link'
                >
                  Contratar Seguro cliente MEI
                </ButtonCTA>
                <ButtonCTA
                  isOrange
                  onClick={handleClickPJ}
                  link={dataLayerPJ?.redirect_url as string}
                  type='Link'
                >
                  Contratar Seguro cliente PJ
                </ButtonCTA>
              </S.BoxButton>
            )}
          </div>
        </div>
      </div>
    </S.CartaoMaisProtegidoEmpresarialSection>
  )
}

export default CartaoMaisProtegidoEmpresarial
