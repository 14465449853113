import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import * as S from './style'
import ButtonCTA from '../../components/ButtonCTA/_index'
import { WIDTH_MD } from 'src/styles/breakpoints'
import useWidth from 'src/hooks/window/useWidth'

const MEICreditCard = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const width = useWidth()

  const dataLayer: IDataLayerParams = {
    section: 'dobra_05',
    section_name: 'Cartão de crédito para MEI',
    element_action: 'click button',
    element_name: 'Abrir conta MEI gratuita',
    redirect_url: 'https://conta-digital-pj.inter.co',
  }

  return (
    <S.Container id='mei-credit-card' className='py-5 bg-gray'>
      <div className='container'>
        <div className='row align-items-center contentbox'>
          <div className='col-12 col-md-6 pr-xl-0'>
            <h2 className='fs-24 fs-lg-32 fs-xl-40 lh-30 lh-lg-40 lh-xl-50 text-grayscale--500 mb-0 mb-md-4'>
              Cartão de crédito para MEI
            </h2>

            {width < WIDTH_MD && (
              <img
                src='https://central-imagens.bancointer.com.br/images-without-small-versions/dobra5-cartaopj-loop/image.webp'
                alt='Mulher negra sorridente exibindo o cartão Inter Empresas'
                className='w-100'
              />
            )}

            <p className='fs-16 lh-20 fs-lg-18 lh-lg-22 text-grayscale--400 mb-4'>
              Os benefícios do cartão de crédito Inter Empresas também se estendem a quem é MEI!
            </p>
            <p className='fs-16 lh-20 fs-lg-18 lh-lg-22 text-grayscale--400 mb-4'>
              Desfrute das vantagens de uma conta MEI empresarial sem taxas ou anuidade.
            </p>
            <div className=''>
              <ButtonCTA
                link={`${(dataLayer?.redirect_url as string)}/login?open`}
                type='Link'
                onClick={() => {
                  sendDatalayerEvent(dataLayer)
                }}
              >
                Abrir conta MEI
              </ButtonCTA>
            </div>
          </div>

          {width >= WIDTH_MD && (
            <div className='col-12 col-md-6 col-lg-5 offset-lg-1 '>
              <img
                src='https://central-imagens.bancointer.com.br/images-without-small-versions/dobra5-cartaopj-loop/image.webp'
                alt='Mulher negra sorridente exibindo o cartão Inter Empresas'
                className='w-100'
              />
            </div>
          )}

        </div>
      </div>
    </S.Container>
  )
}

export default MEICreditCard
