import styled from 'styled-components'

export const Container = styled.div`
    top: 0px;
    left: 0px;

    width: 100%;
    height: 100vh;

    z-index: 1000;
    position: fixed;
    overflow: hidden;

    background-color: rgba(0, 0, 0, 0.4);
`

export const Content = styled.div`
  width: 650px;
  height: 100vh;
  padding: 70px 20px 20px;
  position: absolute;

  background-color: #fff;

  overflow-y: scroll;
  transition: .5s ease-in-out;
  animation: animatedInvitation .5s ease-in-out forwards;
`

export const IconClose = styled.div`
  position: absolute;
  top: 7%;
  right: 7%;
`

export const Card = styled.div`
  width: 340px;
  margin: auto;

  h3{
    font-size: 24px;
    font-weight: 600;
    line-height: 30.24px;
    color: #161616;

    span{
      color: #FF7A00;
    }
  }

`

export const Info = styled.div`
  padding-top: 32px; 
  border-top: 1px solid #DEDFE4;

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    color: #6A6C72
  }
`

export const QrCode = styled.div`
  padding: 32px 0;
  text-align: center;
`
