import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'

export const Container = styled.section`
  display: block;
  padding: 40px 0;

  @media ${device.tablet} {
    padding: 48px 0;
  }

  @media ${device.desktopXL} {
    padding: 64px 0;
  }

  @media ${device.desktopXXL} {
    padding: 90px 0;
  }
`

export const Content = styled.div`
  margin: auto;
  max-width: 312px;

  @media ${device.tablet} {
    max-width: 696px;
  }

  @media ${device.desktopLG} {
    max-width: 936px;
    display: flex;
    
    > div {
      flex-basis: 50%;
    }
  }

  @media ${device.desktopXXL} {
    max-width: 1228px;

    > div:first-of-type {
      flex-basis: 43%;
    }

    > div:last-of-type {
      flex-basis: 57%;
    }
  }
`

export const ContentInfo = styled.div`
  .bread-crumb {
    span, a {
      color: #161616 !important;
    }
  }

  h1{
    font-size: 24px;
    font-weight: 700;
    line-height: 28.8px;
    color: ${grayscale[500]};
    margin: 24px 0 16px 0;
  }

  p {
    font-size: 18px;
    font-weight: 400;
    line-height: 21.78px;
    color: ${grayscale[500]};
    margin-bottom: 24px;
  }

  @media ${device.tablet} {
    h1{
      font-size: 32px;
      line-height: 38.4px;
    }
    p{
      font-size: 18px;
      line-height: 21.78px;
    }
  }

  @media ${device.desktopXXL} {
    h1{
      font-size: 40px;
      line-height: 48px;
    }
    p{
      font-size: 18px;
      line-height: 21.78px;
    }
  }
`

export const ContentImage = styled.div`
  display: none;

  @media ${device.desktopLG} {
    display: block;

    img {
      width: 100%;
    }
  }

  @media ${device.desktopXXL} {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;

    img {
      width: auto;
      max-height: 340px;
    }
  }
`
