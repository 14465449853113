import React, { useState } from 'react'
import Layout from 'src/layouts/BaseLayout'
import { Wrapper } from './style'
import pageContext from './pageContext.json'
import {
  Hero,
  CashBack,
  DebitAndCreditFunctionSection,
  CartaoMaisProtegidoEmpresarial,
  MEICreditCard,
  CreditOnTime,
  PracticalitySection,
  DigitalPayment,
  DigitalWallet,
  BenefitsSection,
  Faq,
} from './sections/_index'
import ModalQRCode from './components/ModalQrCode/_index'

const ContaDigitalPJ = () => {
  const [ isOpenModal, setIsOpenModal ] = useState<boolean>(false)

  const handleChangeModal = () => {
    setIsOpenModal(!isOpenModal)
  }

  return (
    <Wrapper>
      <ModalQRCode onClose={handleChangeModal} show={isOpenModal} />

      <Layout isPJ pageContext={pageContext}>
        <Hero />
        <CashBack />
        <DebitAndCreditFunctionSection />
        <CartaoMaisProtegidoEmpresarial onOpenModal={handleChangeModal} />
        <MEICreditCard />
        <CreditOnTime />
        <PracticalitySection />
        <DigitalPayment />
        <DigitalWallet />
        <BenefitsSection />
        <Faq />
      </Layout>
    </Wrapper>
  )
}

export default ContaDigitalPJ
