import React, { useState } from 'react'
import * as S from './style'
import IconClose from './IconClose.svg'
import QRCodePJ from '../../assets/images/qrcode-pj.svg'
import QRCodeMEI from '../../assets/images/qrcode-mei.svg'
import { Select, SingleValue, SelectOption } from '@interco/inter-ui/components/Select'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

type ModalQRCodeProps = {
  show: boolean;
  onClose: () => void;
}

const options: SelectOption[] = [
  { label: 'Pessoa Física e MEI', value: 'mei' },
  { label: 'Pessoa Jurídica', value: 'pj' },
]

const ModalQRCode = ({ show, onClose }: ModalQRCodeProps) => {
  const [ selected, setSelected ] = useState<SingleValue<SelectOption>>(options[1])

  const [ sendDatalayerEvent ] = useDataLayer()

  const dataLayer: IDataLayerParams = {
    section: 'm_dobra_04',
    section_name: 'Cartão Mais Protegido Empresarial: Proteção extra para a sua conta',
    element_action: 'click button',
    element_name: 'Contratar Seguro cliente',
    redirect_url: 'https://inter-co.onelink.me/Qyu7/vwab6v46',
  }

  const handleOutsideClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if ((e.target as HTMLDivElement).className.includes('modal-overlay')) {
      onClose()
    }
  }

  const handleSelectType = (newValue: SingleValue<SelectOption>) => {
    setSelected(newValue)

    if (newValue?.value === 'mei') {
      const newDataLayer = {
        ...dataLayer,
        element_name: `Modal ${dataLayer?.element_name} MEI`,
      } as IDataLayerParams
      sendDatalayerEvent(newDataLayer)
    }

    if (newValue?.value === 'pj') {
      const newDataLayer = {
        ...dataLayer,
        element_name: `Modal ${dataLayer?.element_name} PJ`,
      } as IDataLayerParams

      sendDatalayerEvent(newDataLayer)
    }
  }

  if (!show) {
    return null
  }

  return (
    <S.Container className='modal-overlay' onClick={handleOutsideClick}>
      <S.Content>
        <S.IconClose onClick={onClose}>
          <img src={IconClose} alt='IconClose' />
        </S.IconClose>
        <S.Card>

          <h3>Acesse o Super App Inter Empresas e contrate o <span>seguro Cartão Mais Protegido</span> agora mesmo!</h3>

          <Select
            options={options}
            defaultValue={options[1]}
            placeholder='Escolha uma opção ...'
            onChange={(value) => handleSelectType(value)}
          />

          <S.QrCode>
            {selected?.value === 'pj' && (
              <img src={QRCodePJ} alt='QRCode' />
            )}
            {selected?.value === 'mei' && (
              <img src={QRCodeMEI} alt='QRCode' />
            )}
          </S.QrCode>

          <S.Info>
            <p>1º: Ligue e aponte a câmera do seu celular para o QR Code acima;</p>
            <p>2º: Você será redirecionado para o Super App;</p>
            <p>3º: Você já pode solicitar o seguro Cartão Mais Protegido</p>
          </S.Info>
        </S.Card>
      </S.Content>
    </S.Container>
  )
}
export default ModalQRCode
