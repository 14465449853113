import React from 'react'
import { CreditOnTimeSection } from './style'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import ButtonCTA from '../../components/ButtonCTA/_index'

const CreditOnTime = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  const dataLayer: IDataLayerParams = {
    section: 'dobra_06',
    section_name: 'Mais limite no seu cartão de crédito PJ',
    element_action: 'click button',
    element_name: 'Abrir conta PJ gratuita',
    redirect_url: 'https://conta-digital-pj.inter.co',
  }

  return (
    <CreditOnTimeSection id='limite-de-credito' className='pt-5'>
      <div className='container pb-5 pb-md-0'>
        <div className='row align-items-end'>
          <div className='col-12 col-md-4 mb-3'>
            <img
              src='https://central-imagens.bancointer.com.br/images-without-small-versions/dobra6-cartaopj-loop/image.webp'
              alt='Aplicativo Inter Empresas'
              className='w-100'
            />
          </div>
          <div className='col-12 col-md-8 col-lg-7 offset-lg-1 pb-md-5 pb-lg-5'>
            <h2 className='fs-24 fs-lg-32 fs-xl-40 lh-30 lh-lg-40 lh-xl-50 text-left text-grayscale--500'>
              Mais limite no seu cartão de crédito PJ
            </h2>
            <p className='fs-14 fs-lg-18 lh-18 lh-lg-22 text-left'>
              Com o CDB Mais Limite de Crédito, seu investimento se torna limite para o cartão de crédito da sua empresa, podendo usar por quanto tempo quiser.
            </p>
            <ButtonCTA
              type='Link'
              link={`${(dataLayer?.redirect_url as string)}/login?open`}
              onClick={() => {
                sendDatalayerEvent(dataLayer)
              }}
            >
              Abrir conta PJ
            </ButtonCTA>
          </div>
        </div>
      </div>
    </CreditOnTimeSection>
  )
}

export default CreditOnTime
