import React from 'react'
import * as S from './style'

import BreadCrumb from 'src/components/Breadcrumb'
import ButtonCTA from '../../components/ButtonCTA/_index'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

const Hero = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  const dataLayer: IDataLayerParams = {
    section: 'dobra_01',
    section_name: 'Cartão de Crédito Empresarial',
    element_action: 'click button',
    element_name: 'Abrir conta PJ gratuita',
    redirect_url: 'https://conta-digital-pj.inter.co',
  }

  const handleClick = () => {
    sendDatalayerEvent(dataLayer)
  }

  return (
    <S.Container id='cartao-pj'>
      <S.Content>
        <S.ContentInfo>
          <BreadCrumb
            sectionName='Cartão de Crédito Empresarial'
            type='Empresas'
            text='Cartão Empresarial'
            link='/empresas/cartao-de-credito-empresarial/'
          />
          <h1>Cartão de Crédito Empresarial</h1>
          <p>Com o Inter Empresas, você tem cartão com função de débito e crédito, sem anuidade e ainda acumula pontos em cada compra realizada.</p>
          <ButtonCTA
            onClick={handleClick}
            link={`${(dataLayer?.redirect_url as string)}/login?open`}
            type='Link'
          >
            Abrir conta PJ/MEI
          </ButtonCTA>
        </S.ContentInfo>
        <S.ContentImage>
          <img
            src='https://central-imagens.bancointer.com.br/images-without-small-versions/loop-pj-herobanner/image.webp'
            alt='Cartão de crédito Inter Empresas'
          />
        </S.ContentImage>
      </S.Content>
    </S.Container>
  )
}

export default Hero
