import styled from 'styled-components'

import { white, gray, grayscale } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

export const Wrapper = styled.div`
  h1, h2, h3, h4, h5 {
    font-family: 'Citrina', Helvetica, sans-serif;
  }
  p {
    font-family: 'Inter', Helvetica, sans-serif;
  }
`

export const ContentHeader = styled.section`
  .bread-crumb {
    span,
    a {
      color: #161616 !important;
    }
  }
  min-height: 100vh;

  @media ${device.tablet} {
    align-items: center;
    min-height: 680px;
  }

  @media ${device.desktopLG} {
    min-height: 100vh;
  }

  &:before {
    background-image: none;
  }

  > .container {
    padding-bottom: 0;
    padding-top: 40px;

    @media ${device.tablet} {
      padding-top: 0;
    }

    &.animated {
      opacity: 1;
    }
  }

  button,
  a {
    @media ${device.desktopLG} {
      max-width: 376px;
    }
    @media ${device.desktopXL} {
      max-width: 456px;
    }
  }
`

export const ScrollLink = styled.div`
  position: absolute;
  bottom: 20px;
  left: 50%;
  text-align: center;
  line-height: 1.67;
  transform: translateX(-50%);
  width: auto;
`

export const SolutionsTable = styled.div`
  @media ${device.desktopLG} {
    padding: 0 80px;
  }
  @media ${device.desktopXL} {
    padding: 0 10px;
  }
`

export const TableCell = styled.div`
  div {
    width: 33%;

    img {
      width: 24px;
    }
    @media ${device.tablet} {
      width: 60%;
      :first-child {
        width: 25%;
      }
      :last-child {
        width: 15%;
      }
    }
  }

  .text-green-table {
    color: #007a75;
  }

  .logo-inter {
    width: 38px;
    height: 16px;
  }

  .border-green {
    border-left: 1px solid #007a75;
    border-right: 1px solid #007a75;
    padding: 15px 0 15px 0;
  }

  .border-green-top {
    border-left: 1px solid #007a75;
    border-right: 1px solid #007a75;
    border-top: 1px solid #007a75;
    padding: 15px 0 15px 0;
    border-radius: 8px 8px 0 0;
  }

  .border-green-bottom {
    border-left: 1px solid #007a75;
    border-right: 1px solid #007a75;
    border-bottom: 1px solid #007a75;
    padding: 29px 0 29px 0;
    border-radius: 0 0 8px 8px;
  }
`

export const HR = styled.hr`
  margin: 0;
`

export const HowToRequestSection = styled.section`
  button {
    @media ${device.desktopLG} {
      max-width: 376px;
    }
    @media ${device.desktopXL} {
      max-width: 456px;
    }
  }
  h2 {
    + p {
      font-family: "Sora", Helvetica, sans-serif;
    }
  }

  .react-multi-carousel-dot-list {
    @media (min-width: 1600px) {
      width: 456px;
    }
    .react-multi-carousel-dot {
      button {
        background-color: white !important;
        @media ${device.desktopXL} {
          top: 200px;
        }
      }
    }
  }

  .react-multiple-carousel__arrow--left {
    left: 0;
    @media ${device.tablet} {
      left: -7px;
    }
  }

  .react-multiple-carousel__arrow--right {
    right: 0;

    @media ${device.tablet} {
      right: -7px;
    }
    @media ${device.desktopLG} {
      right: 3px;
    }
    @media (min-width: 1600px) {
      right: 122px;
    }
  }

  button {
    svg {
      fill: white;
    }
  }

  .description {
    min-height: 100px;
    @media ${device.tablet} {
      min-height: auto;
    }
  }
`

export const ImgSizeCarousel = styled.div`
  .gatsby-image-wrapper {
    width: 312px;
    margin: 0 auto;

    @media ${device.tablet} {
      width: 336px;
    }
    @media ${device.desktopLG} {
      width: 456px;
    }
  }
`

export const ImgSize = styled.div`
  .gatsby-image-wrapper {
    @media ${device.desktopLG} {
      width: 456px;
    }
  }
`

export const TextCarousel = styled.div`
  min-height: 200px;
  @media ${device.tablet} {
    min-height: auto;
  }
`

export const BlogCarousel = styled.div`
  .primary-tag,
  .secundary-tag {
    display: inline;
  }

  &.p-card {
    padding: 0;

    @media ${device.tablet} {
      padding-right: 24px;
    }
    @media ${device.desktopLG} {
      padding-right: 10.5px;
      padding-left: 10.5px;
    }
  }

  article {
    display: grid;
    min-height: 350px;
    @media ${device.desktopLG} {
      min-height: 470px;
    }
  }

  .react-multi-carousel-track {
    padding: 30px 0 10px !important;
  }

  .title-post {
    font-family: "Sora", Helvetica, sans-serif;
  }
`

export const TabSection = styled.section`
  .h-panel {
    min-height: 540px;

    @media ${device.tablet} {
      min-height: 368px;
    }

    @media ${device.desktopLG} {
      min-height: 489px;
    }

    @media ${device.desktopXL} {
      min-height: 613px;
    }

    .invest {
      margin-bottom: 30px;

      @media ${device.tablet} {
        margin-bottom: 100px;
      }

      @media ${device.desktopXXL} {
        margin-bottom: 250px;
      }
    }
  }

  .react-multi-carousel-dot {
    margin: 0;
  }

  .tab-conta-digital-pj {
    ul {
      border-bottom: 4px solid #f5f6fa;
      justify-content: start !important;
      list-style: none;
      display: flex;
      flex-wrap: no-wrap;
      overflow-x: auto;
      overflow-y: hidden;
      height: 36px;

      @media ${device.tablet} {
        margin-left: 15px;
      }

      li {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
        margin-right: 32px;
        line-height: 17px;
        margin-left: 0;

        button {
          padding-bottom: 10px;

          &:first-child {
            padding-left: 0;
          }
        }
      }
    }
  }

  button {
    @media ${device.desktopLG} {
      max-width: 376px;
    }
    @media ${device.desktopXL} {
      max-width: 456px;
    }
  }

  @media ${device.desktopLG} {
    .gatsby-image-wrapper {
      max-width: 456px;
    }
  }
`

export const ForWhoIsSection = styled.section`
  @media ${device.tablet} {
    background: ${gray['400']};
  }

  button {
    @media ${device.desktopLG} {
      max-width: 376px;
    }
    @media ${device.desktopXL} {
      max-width: 456px;
    }
  }
`

export const BoxWhiteCarousel = styled.div`
  > div {
    min-height: 160px;
    padding: 35px 30px;
    @media ${device.tablet} {
      min-height: 210px;
    }

    @media ${device.desktopXL} {
      min-height: 248px;
      padding-top: 60px;
    }
  }
  li.react-multi-carousel-dot {
    margin: 0;
  }
`

export const UniverseOfOpportunitiesSection = styled.section`

  align-items: center;

  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;

  @media ${device.tablet} {
    min-height: 416px;
    padding-top: 100px;
  }

  @media ${device.desktopLG} {
    min-height: 584px;
  }

  @media ${device.desktopXL} {
    min-height: 768px;
  }
`

export const OpenVideoContainer = styled.div`
  cursor: pointer;
  max-width: 100%;

  @media ${device.desktopLG} {
    max-width: 180px;
  }
`

export const PerguntasFrequentes = styled.section`
  .summary-details {
    .summary {
      .summary-content {
        align-items: center;
        p {
          color: ${grayscale[500]};
        }
        span {
          color: ${grayscale[500]};
        }
      }
    }
  }

  .search-input {
    background: ${white};
  }
`

export const FaresCard = styled.div`
  p {
    height: 90px;
    @media ${device.desktopLG} {
      height: 70px;
    }
    @media ${device.desktopXL} {
      height: 50px;
    }
  }
  h4 {
    height: 40px;
    @media ${device.desktopLG} {
      height: 30px;
    }
  }
  .bg-white {
    @media ${device.tablet} {
      min-height: 186px;
    }
    @media ${device.desktopLG} {
      min-height: 146px;
    }
    @media ${device.desktopXL} {
      min-height: 140px;
    }
  }
`
