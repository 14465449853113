import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { grayscale, white, laranja, brand } from 'src/styles/colors'

export const CartaoMaisProtegidoEmpresarialSection = styled.section`
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: ${laranja.terra};

  @media ${device.tablet} {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  @media ${device.desktopXL} {
    padding-top: 96px;
    padding-bottom: 96px;
  }
`
export const PlansContent = styled.div`
  display: flex;
  gap: 32px;
  flex-direction: column;
  padding: 24px 0;

  @media ${device.tablet} {
    padding: 48px 0;
    flex-direction: row;
  }
`

export const Card = styled.div`
  width: 100%;
`

export const CardHeader = styled.div`
  background-color: #E6E4E4;
  border-radius: 12px 12px 0 0;

  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h3 {
    font-size: 16px;
    font-weight: 600;
    line-height: 20.16px;
    margin: 0;
    color: #625A59;
  }

  p {
    font-size: 20px;
    font-weight: 600;
    line-height: 25.2px;
    margin: 0;
    color: #625A59;
  }
`

export const CardContent = styled.div`
  background-color: ${white};
  border-radius: 0 0 12px 12px;
  padding: 24px 8px;

  @media ${device.desktopLG} {
    padding: 24px 32px;
  }

  @media ${device.desktopXL} {
    padding: 24px 64px;
  }
`

export const CardInfo = styled.div`
  width: 205px;
  margin: auto;

  margin-bottom: 24px;

  &:after {
    content: "";
    width: 50%;
    margin: 0 auto;
    display: block;
    padding-top: 16px;
    border-bottom: 2px solid ${grayscale[200]};
  }
  &:last-child:after {
    display: none;
  }

  p {
    color: #161616;

    font-size: 16px;
    line-height: 19.36px;

    margin: 0;
    text-align: center;
  }
  
  strong {
    color: ${brand.primary}
  }
`

export const BoxButton = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`
