import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { gray, laranja } from 'src/styles/colors'

export const Container = styled.section`
  display: block;
  padding: 40px 0;
  background-color: ${laranja.terra};

  @media ${device.tablet} {
    padding: 48px 0;
  }

  @media ${device.desktopXL} {
    padding: 64px 0;
  }

  @media ${device.desktopXXL} {
    padding: 90px 0;
  }
`

export const Content = styled.div`
  margin: auto;
  max-width: 312px;

  @media ${device.tablet} {
    max-width: 696px;
    display: flex;
    flex-direction: row-reverse;
    
    > div {
      flex-basis: 50%;
    }
  }

  @media ${device.desktopLG} {
    max-width: 936px;

  }

  @media ${device.desktopXXL} {
    max-width: 1228px;

    > div:first-of-type {
      flex-basis: 57%;
    }

    > div:last-of-type {
      flex-basis: 43%;
    }
  }
`

export const ContentInfo = styled.div`
  h2{
    font-size: 24px;
    font-weight: 700;
    line-height: 28.8px;
    color: ${gray[400]};
    margin: 32px 0;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 17.64px;
    color: ${gray[400]};
    margin-bottom: 24px;
  }

  @media ${device.tablet} {
    h2{
      font-size: 32px;
      line-height: 38.4px;
    }
  }

  @media ${device.desktopLG} {
    p{
      font-size: 18px;
      line-height: 21.78px;
    }
  }

  @media ${device.desktopXXL} {
    h2{
      font-size: 40px;
      line-height: 48px;
    }
    p{
      font-size: 16px;
      line-height: 20.16px;
    }

    button {
      width: 95%;
    }
  }
`

export const ContentImage = styled.div`
  img {
    width: 100%;
  }

  @media ${device.tablet} {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  @media ${device.desktopXXL} {
    img {
      width: auto;
      max-height: 100%;
    }
  }
`
