import React from 'react'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import { Benefits, LineDivision } from './style'
import cardData from '../../assets/data/AdvantagesOfInterPjCard.json'
import { IBenefits } from './types'
import useWidth from 'src/hooks/window/useWidth'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { WIDTH_LG, WIDTH_MD } from 'src/styles/breakpoints'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

const BenefitsSection = () => {
  const width = useWidth()
  const [ sendDatalayerEvent ] = useDataLayer()

  const dataLayer: IDataLayerParams = {
    section: 'dobra_10',
    section_name: 'Cartão Inter Mastercard Microbusiness',
    element_action: 'click button',
  } as IDataLayerParams

  return (
    <Benefits id='beneficios' className='py-5'>
      <div className='container pt-1 '>
        <h2 className='fs-24 fs-lg-40 lh-30 lh-lg-50 text-lg-center text-grayscale--500 text-md-center'>
          Cartão Inter Mastercard Microbusiness
        </h2>
        <p className='fs-14 fs-lg-18 lh-18 lh-lg-22 text-lg-center text-grayscale--400 text-md-center'>
          Garanta segurança e flexibilidade nos seus pagamentos, com benefícios incríveis!
        </p>

        {width < WIDTH_MD && (
          <div className='col-12 mt-5'>
            <div className='row align-items-start d-flex justify-content-between'>
              <div className='col-12 col-md-5 order-md-2'>
                <img
                  src='https://central-imagens.bancointer.com.br/images-without-small-versions/dobra9-cartao/image.webp'
                  alt='Imagem do cartão de crédito empresarial do Inter.'
                  className='card-image'
                />
              </div>

              <div className='col-12 col-md-6 col-xl-4'>
                {cardData.benefits.map((benefit: IBenefits) => (
                  <div key={benefit.icon} className='d-flex align-items-center'>
                    <div className='icon-circle d-flex justify-content-center align-items-center mr-md-3'>
                      <OrangeIcon
                        size='MD'
                        color='#72370E'
                        icon={benefit.icon}
                      />
                    </div>
                    <h6 className='fs-16 lh-20 text-grayscale--500 fw-600 mt-3 pl-3'>{benefit.description}</h6>
                  </div>
                ))}
                <div>
                  <LineDivision />
                </div>
              </div>

              <div className='col-md-6 col-xl-4 row'>
                <div className='col col-xl-8'>
                  <div className='d-flex align-items-center align-items-md-start'>
                    <div className='mr-2 mr-md-3 mr-lg-3'>
                      <OrangeIcon
                        size='MD'
                        color='#72370E'
                        icon='gift'
                      />
                    </div>
                    <p className='fs-16 lh-20 text-grayscale--400 fw-100 mt-4 mt-md-0 ml-md-1 ml-lg-0 mb-lg-4 pl-2'>
                      Vantagens exclusivas do programa de benefícios Mastercard Surpreenda.
                      <a
                        style={{ color: '#72370E' }}
                        href='https://surpreenda.naotempreco.com.br/'
                        target='_blank'
                        rel='noreferrer'
                        className='text-new-orange-dark fw-600'
                        onClick={() => {
                          sendDatalayerEvent({
                            ...dataLayer,
                            element_name: 'Saiba mais',
                            redirect_url: 'https://surpreenda.naotempreco.com.br/',
                          })
                        }}
                      >
                        {' '}Saiba mais
                      </a>
                    </p>
                  </div>
                  <div className='d-flex align-items-center align-items-md-start'>
                    <div className='mr-2 mr-md-3 mr-lg-3'>
                      <OrangeIcon
                        size='MD'
                        color='#72370E'
                        icon='plus-arrows'
                      />
                    </div>
                    <p className='fs-16 lh-20 text-grayscale--400 fw-100 mt-4 mt-md-0 ml-md-1 ml-lg-0 mb-lg-4 pl-2'>
                      <span className='text-orange--base fw-600'>
                        <a
                          style={{ color: '#72370E' }}
                          href='https://www.mastercard.com.br/pt-br/empresas/pequenas-empresas/cartoes/micro-business.html'
                          target='_blank'
                          rel='noreferrer'
                          className='text-new-orange-dark fw-600'
                          onClick={() => {
                            sendDatalayerEvent({
                              ...dataLayer,
                              element_name: 'Conheça mais sobre os benefícios',
                              redirect_url: 'https://www.mastercard.com.br/pt-br/empresas/pequenas-empresas/cartoes/micro-business.html',
                            })
                          }}
                        >
                          Conheça mais sobre os benefícios
                        </a>
                      </span> dos cartão Microbusiness Mastercard, e abra a sua
                      <span className='text-orange--base fw-600'>
                        <a
                          href='https://conta-digital-pj.inter.co/login?open'
                          target='_blank'
                          rel='noreferrer'
                          className='text-new-orange-dark fw-600'
                          onClick={() => {
                            sendDatalayerEvent({
                              ...dataLayer,
                              element_name: 'Conta Digital Gratuita',
                              redirect_url: 'https://conta-digital-pj.inter.co/login?open',
                            })
                          }}
                        >
                          {' '}Conta Digital Gratuita
                        </a>
                      </span>.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {width === WIDTH_MD && (
          <div className='col-12 mt-5'>
            <div className='row align-items-start d-flex justify-content-between'>
              <div className='col-12 col-md-6'>
                {cardData.benefits.map((benefit: IBenefits) => (
                  <div key={benefit.icon} className='d-flex align-items-center'>
                    <div className='icon-circle d-flex justify-content-center align-items-center mr-md-3'>
                      <OrangeIcon
                        size='MD'
                        color='#72370E'
                        icon={benefit.icon}
                      />
                    </div>
                    <h6 className='fs-16 lh-20 text-grayscale--500 fw-600 mt-3 pl-3'>{benefit.description}</h6>
                  </div>
                ))}
              </div>

              <div className='col-12 col-md-6'>
                <img
                  src='https://central-imagens.bancointer.com.br/images-without-small-versions/dobra9-cartao/image.webp'
                  alt='Imagem do cartão de crédito empresarial do Inter.'
                  className='card-image'
                />
              </div>

              <div className='col-12 row'>
                <div className='col-12 mb-5 mt-2'>
                  <LineDivision />
                </div>
                <div className='col-12'>
                  <div className='d-flex align-items-center align-items-md-start'>
                    <div className='mr-2 mr-md-3 mr-lg-3'>
                      <OrangeIcon
                        size='MD'
                        color='#72370E'
                        icon='gift'
                      />
                    </div>
                    <p className='fs-16 lh-20 text-grayscale--400 fw-100 mt-4 mt-md-0 ml-md-1 ml-lg-0 mb-lg-4 pl-2'>
                      Vantagens exclusivas do programa de benefícios Mastercard Surpreenda.
                      <a
                        style={{ color: '#72370E' }}
                        href='https://surpreenda.naotempreco.com.br/'
                        target='_blank'
                        rel='noreferrer'
                        className='text-new-orange-dark fw-600'
                        onClick={() => {
                          sendDatalayerEvent({
                            ...dataLayer,
                            element_name: 'Saiba mais',
                            redirect_url: 'https://surpreenda.naotempreco.com.br/',
                          })
                        }}
                      >
                        {' '}Saiba mais
                      </a>
                    </p>
                  </div>
                  <div className='d-flex align-items-center align-items-md-start'>
                    <div className='mr-2 mr-md-3 mr-lg-3'>
                      <OrangeIcon
                        size='MD'
                        color='#72370E'
                        icon='plus-arrows'
                      />
                    </div>
                    <p className='fs-16 lh-20 text-grayscale--400 fw-100 mt-4 mt-md-0 ml-md-1 ml-lg-0 mb-lg-4 pl-2'>
                      <span className='text-orange--base fw-600'>
                        <a
                          style={{ color: '#72370E' }}
                          href='https://www.mastercard.com.br/pt-br/empresas/pequenas-empresas/cartoes/micro-business.html'
                          target='_blank'
                          rel='noreferrer'
                          className='text-new-orange-dark fw-600'
                          onClick={() => {
                            sendDatalayerEvent({
                              ...dataLayer,
                              element_name: 'Conheça mais sobre os benefícios',
                              redirect_url: 'https://www.mastercard.com.br/pt-br/empresas/pequenas-empresas/cartoes/micro-business.html',
                            })
                          }}
                        >
                          Conheça mais sobre os benefícios
                        </a>
                      </span> dos cartão Microbusiness Mastercard, e abra a sua
                      <span className='text-orange--base fw-600'>
                        <a
                          href='https://conta-digital-pj.inter.co/login?open'
                          target='_blank'
                          rel='noreferrer'
                          className='text-new-orange-dark fw-600'
                          onClick={() => {
                            sendDatalayerEvent({
                              ...dataLayer,
                              element_name: 'Conta Digital Gratuita',
                              redirect_url: 'https://conta-digital-pj.inter.co/login?open',
                            })
                          }}
                        >
                          {' '}Conta Digital Gratuita
                        </a>
                      </span>.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {width >= WIDTH_LG && (
          <div className='col-12 mt-5'>
            <div className='row align-items-start d-flex justify-content-between'>
              <div className='col-6'>

                <div className='col-12'>
                  {cardData.benefits.map((benefit: IBenefits) => (
                    <div key={benefit.icon} className='d-flex align-items-center'>
                      <div className='icon-circle d-flex justify-content-center align-items-center mr-md-3'>
                        <OrangeIcon
                          size='MD'
                          color='#72370E'
                          icon={benefit.icon}
                        />
                      </div>
                      <h6 className='fs-16 lh-20 text-grayscale--500 fw-600 mt-3 pl-3'>{benefit.description}</h6>
                    </div>
                  ))}
                  <div className='mb-4 mt-4'>
                    <LineDivision />
                  </div>
                </div>

                <div className='col-12'>
                  <div className='col col-xl-8'>
                    <div className='d-flex align-items-center align-items-md-start'>
                      <div className='mr-2 mr-md-3 mr-lg-3'>
                        <OrangeIcon
                          size='MD'
                          color='#72370E'
                          icon='gift'
                        />
                      </div>
                      <p className='fs-16 lh-20 text-grayscale--400 fw-100 mt-4 mt-md-0 ml-md-1 ml-lg-0 mb-lg-4'>
                        Vantagens exclusivas do programa de benefícios Mastercard Surpreenda.
                        <a
                          style={{ color: '#72370E' }}
                          href='https://surpreenda.naotempreco.com.br/'
                          target='_blank'
                          rel='noreferrer'
                          className='text-new-orange-dark fw-600'
                          onClick={() => {
                          sendDatalayerEvent({
                            ...dataLayer,
                            element_name: 'Saiba mais',
                            redirect_url: 'https://surpreenda.naotempreco.com.br/',
                          })
                          }}
                        >
                          {' '}Saiba mais
                        </a>
                      </p>
                    </div>

                    <div className='d-flex align-items-center align-items-md-start'>
                      <div className='mr-2 mr-md-3 mr-lg-3'>
                        <OrangeIcon
                          size='MD'
                          color='#72370E'
                          icon='plus-arrows'
                        />
                      </div>
                      <p className='fs-16 lh-20 text-grayscale--400 fw-100 mt-4 mt-md-0 ml-md-1 ml-lg-0 mb-lg-4'>
                        <span className='text-orange--base fw-600'>
                          <a
                            style={{ color: '#72370E' }}
                            href='https://www.mastercard.com.br/pt-br/empresas/pequenas-empresas/cartoes/micro-business.html'
                            target='_blank'
                            rel='noreferrer'
                            className='text-new-orange-dark fw-600'
                            onClick={() => {
                              sendDatalayerEvent({
                                ...dataLayer,
                                element_name: 'Conheça mais sobre os benefícios',
                                redirect_url: 'https://www.mastercard.com.br/pt-br/empresas/pequenas-empresas/cartoes/micro-business.html',
                               })
                            }}
                          >
                            Conheça mais sobre os benefícios
                          </a>
                        </span> dos cartão Microbusiness Mastercard, e abra a sua
                        <span className='text-orange--base fw-600'>
                          <a
                            href='https://conta-digital-pj.inter.co/login?open'
                            target='_blank'
                            rel='noreferrer'
                            className='text-new-orange-dark fw-600'
                            onClick={() => {
                              sendDatalayerEvent({
                                ...dataLayer,
                                element_name: 'Conta Digital Gratuita',
                                redirect_url: 'https://conta-digital-pj.inter.co/login?open',
                              })
                            }}
                          >
                            {' '}Conta Digital Gratuita
                          </a>
                        </span>.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className='col-6'>
                <img
                  src='https://central-imagens.bancointer.com.br/images-without-small-versions/dobra9-cartao/image.webp'
                  alt='Imagem do cartão de crédito empresarial do Inter.'
                  className='card-image'
                />
              </div>
            </div>
          </div>
        )}

      </div>
    </Benefits>
  )
}

export default BenefitsSection
