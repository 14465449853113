import React from 'react'
import ButtonCTA from '../../components/ButtonCTA/_index'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

const DigitalPayment = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  const dataLayer: IDataLayerParams = {
    section: 'dobra_08',
    section_name: 'Pagamentos digitais: Facilidade ao seu alcance',
    element_action: 'click button',
    element_name: 'Conheça os pagamentos digitais',
    redirect_url: 'https://inter.co/pra-voce/cartoes/carteiras-digitais/',
  }

  return (
    <section id='carteiras-digitais'>
      <div className='container pt-5'>
        <div className='row align-items-center justify-content-between'>
          <div className='col-12 col-md-6 col-xl-7'>
            <h2 className='fs-24 lh-30 fs-lg-32 fs-xl-40 lh-lg-40 lh-xl-50 mt-4 mt-md-2 text-grayscale--500'>
              Pagamentos digitais: Facilidade ao seu alcance
            </h2>
            <p className='fs-14 fs-lg-18 lh-18 lh-lg-22 pb-4'>
              <span className='d-xl-block'>Pague no crédito ou no débito usando apenas o celular, relógio ou pulseira, dispensando o uso do cartão físico.</span>
              <span className='d-xl-block mt-3'>Basta adicionar seu cartão Inter em alguma das carteiras a abaixo.</span>
            </p>
            <ButtonCTA
              type='Link'
              link={dataLayer?.redirect_url as string}
              onClick={() => {
                sendDatalayerEvent(dataLayer)
              }}
            >
              Conheça os pagamentos digitais
            </ButtonCTA>

          </div>
          <div className='d-none d-sm-block col-12 col-md-5'>
            <img
              src='https://central-imagens.bancointer.com.br/images-without-small-versions/dobra8-cartoes/image.webp'
              alt='Homem sorridente pagando sua conta no café com o cartão de crédito empresarial Inter por aproximação através de um smartphone'
              className='float-lg-right w-100'
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default DigitalPayment
