import React from 'react'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import DigitalWalletContent from '../../assets/data/DigitalWalletContent.json'
import ApplePayLogo from '../../assets/images/apple-pay.png'
import GooglePayLogo from '../../assets/images/google-pay.png'
import SamsungPayLogo from '../../assets/images/samsung-pay.png'
import WhatsAppPayLogo from '../../assets/images/whats-app-pay.png'
import { ApplePay, LogosPay } from './style'
import { IDigitalWallet } from './types'

const SwitchIcon = ({ name }: {name: string}) => {
  switch (name) {
    case 'whatsAppPay':
      return <img src={WhatsAppPayLogo} alt={name} />
    case 'apllePay':
      return <img src={ApplePayLogo} alt={name} />
    case 'googlePay':
      return <img src={GooglePayLogo} alt={name} />
    case 'samsungPay':
      return <img src={SamsungPayLogo} alt={name} />
    default:
      return null
  }
}

const DigitalWallet = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <section>
      <div className='container pb-5'>
        <div className='row'>
          <ApplePay className='col-12 d-md-none'>
            <DefaultCarousel
              sm={{
                items: 1,
                partialVisibilityGutter: 70,
              }}
              md={{ items: 4 }}
              lg={{ items: 3 }}
              xl={{ items: 3 }}
              removeArrowOnDeviceType={[ 'desktop' ]}
            >
              {
                DigitalWalletContent.map((item: IDigitalWallet) => (
                  <LogosPay key={item.title} className='rounded-3 pt-4 pt-xl-5 px-3 mr-2 mr-md-3 bg-gray'>
                    <div className='logos d-flex align-items-center'>
                      <SwitchIcon name={item?.logo} />
                    </div>
                    <p className='fs-16 lh-20 text-grayscale--500 fw-600'>{item.title}</p>
                    <p className='fs-14 fs-lg-16 lh-17 lh-lg-22 text-grayscale--400'>{item.description}</p>
                    <p className='fs-16 lh-20 text-orange--light fw-600 d-none more-details'>
                      <a
                        href={item.url}
                        target='_blank'
                        title={`Mais detalhes sobre ${item.title}`}
                        onClick={() => {
                          sendDatalayerEvent({
                            section: 'dobra_09',
                            section_name: 'Cards de Pagamento',
                            element_action: 'click button',
                            element_name: `Mais detalhes + ${item.title}`,
                            redirect_url: item.url,
                          })
                        }} rel='noreferrer'
                      >
                        Mais detalhes
                      </a>
                    </p>
                  </LogosPay>
                ))
              }
            </DefaultCarousel>
          </ApplePay>
        </div>
        <div className='row my-5'>
          {
            DigitalWalletContent.map((item: IDigitalWallet) => (
              <div key={item.title} className='col-md-6 col-lg-3 d-none d-md-inline px-2'>
                <LogosPay>
                  <div className='rounded-3 bg-gray px-4 px-md-3 py-4 content-text'>
                    <div className='logos d-flex align-items-center'>
                      {item?.logo === 'whatsAppPay' && (
                        <img src={WhatsAppPayLogo} alt={item?.logo} />
                      )}

                      {item?.logo === 'apllePay' && (
                        <img src={ApplePayLogo} alt={item?.logo} />
                      )}

                      {item?.logo === 'googlePay' && (
                        <img src={GooglePayLogo} alt={item?.logo} />
                      )}

                      {item?.logo === 'samsungPay' && (
                        <img src={SamsungPayLogo} alt={item?.logo} />
                      )}
                    </div>
                    <p className='fs-16 lh-20 text-grayscale--500 fw-600'>{item.title}</p>
                    <p className='fs-14 fs-lg-16 lh-17 lh-lg-22 text-grayscale--400'>{item.description}</p>
                    <p className='fs-16 lh-20 text-orange--light fw-600 d-none d-md-block more-details'>
                      <a
                        href={item.url}
                        target='_blank'
                        rel='noreferrer'
                        title={`Mais detalhes sobre ${item.title}`}
                        onClick={() => {
                        sendDatalayerEvent({
                          section: 'dobra_09',
                          section_name: 'Cards de Pagamento',
                          element_action: 'click button',
                          element_name: `Mais detalhes + ${item.title}`,
                          redirect_url: item.url,
                        })
                        }}
                      >
                        Mais detalhes
                      </a>
                    </p>
                  </div>
                </LogosPay>
              </div>
            ))
          }
        </div>
        <p className='d-none d-sm-block fs-12 lh-15 mb-2'>A carteira digital <span className='fw-700'>Apple Pay</span> está disponível, no momento, apenas para <span className='fw-700'>clientes MEI.</span> Os demais meios de pagamento já podem ser utilizados pelas duas modalidades, MEI e PJ.</p>
        <p className='d-none d-sm-block fs-12 lh-15'>Sempre escolha o <span className='fw-700'>SMS como token de autenticação</span> na hora do cadastro do cartão nas carteiras digitais <span className='fw-700'>Google Pay</span> e <span className='fw-700'>Samsung Pay.</span></p>
      </div>
    </section>
  )
}

export default DigitalWallet
