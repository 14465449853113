import { breakpoints, device } from 'src/styles/breakpoints'
import { orange } from 'src/styles/colors'
import { rounded } from 'src/styles/rounded'
import styled from 'styled-components'

export const LineDivision = styled.hr`
  width: 90%;
  height: 0.5px;
  background: #E6E7F0;
  border-top: none;
  margin: 20px 5% 0 5%;

  @media (min-width: ${breakpoints.md}) {
    width: 100%;
  }
`

export const Tab = styled.section`
  .tab-names {
    h3 {
      padding: 0 20px;
      cursor: pointer;
    }
  }
  .active {
    color: ${orange.extra};
    border-bottom: 4px  solid ${orange.extra};
  }
`

export const Benefits = styled.section`
  .icon-circle img {
    max-width: 29px;
    background-color: #FFF2E7;
    border-radius: ${rounded['5']};
  }

  .card-image {
    max-width: 309px;
    @media ${device.desktopLG} {
      max-width: 456px;
    }
  }

  ul {
    border-bottom: 1px solid #F5F6FA;
    display: flex !important;
    justify-content: space-evenly
  }

  li {
    button {
      font-family: "Sora", Helvetica, sans-serif;
      padding: 0 29px 8px;
      @media ${device.tablet} {
        padding: 0 35px 10px;
      }
      @media ${device.desktopLG} {
        padding: 0 45px 12px;;
      }
      @media ${device.desktopXL} {
        padding: 0 45px 12px;;
      }
    }
  }
`
