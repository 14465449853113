import React from 'react'
import * as S from './style'
import { WIDTH_MD } from 'src/styles/breakpoints'
import useWidth from 'src/hooks/window/useWidth'

type ButtonCTAProps = {
  onClick: () => void;
  children: React.ReactNode;

  isResponsive?: boolean;
  type?: 'Button' | 'Link';
  link?: string;
  isOrange?: boolean;
};

const ButtonCTA = ({ onClick, children, isResponsive, type, link, isOrange }: ButtonCTAProps) => {
  const width = useWidth()

  if (isResponsive && width <= WIDTH_MD) {
    return (
      <S.LinkButton
        href={link}
        target='_blank'
        rel='noreferrer'
        onClick={onClick}
        isOrange={isOrange}
      >
        {children}
      </S.LinkButton>
    )
  }

  if (type === 'Link') {
    return (
      <S.LinkButton
        href={link}
        target='_blank'
        rel='noreferrer'
        onClick={onClick}
        isOrange={isOrange}
      >
        {children}
      </S.LinkButton>
    )
  }

  return (
    <S.Button onClick={onClick} isOrange={isOrange}>
      {children}
    </S.Button>
  )
}

export default ButtonCTA
