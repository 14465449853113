import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'

export const ApplePay = styled.div`
  .react-multiple-carousel__arrow--left {
    left: 0;
  }
  .react-multiple-carousel__arrow--right {
    right: 0;
  }
`

export const LogosPay = styled.div`
  background-color: #F5F6FA;
  border-radius: 8px;

  .logos {
    min-height: 83px;
  }
  .content-text {
    @media ${device.tablet} {
      min-height: 291px;
    }
    @media ${device.desktopLG} {
      min-height: 413px;
    }
    @media ${device.desktopXL} {
      min-height: 363px;
    }
  }
  min-height: 280px;
  @media ${device.desktopLG} {
    min-height: 300px;
  }
  @media ${device.desktopXL} {
    min-height: 330px;
  }
  .gatsby-image-wrapper {
    max-width: 175px;
  }
  .more-details {
    bottom: 10px;
    position: absolute;
  }
`
