import { device } from 'src/styles/breakpoints'
import { gray } from 'src/styles/colors'
import styled from 'styled-components'

export const DebitAndCreditFunctionSection = styled.section`
  .content-info{
    p {
      margin: 0;
    }
  }

  .card-advantages {
    min-height: 220px;
  }
  @media ${device.tablet} {
    .carousel-advantages {
      li:last-child {
        margin-top: 90px;
      }
    }
  }
  .react-multiple-carousel {
    &__arrow--right {
      right: 0;
        @media ${device.tablet} {
          right: 166px;
        }
    }
    &__arrow--left {
      left: 0;
        @media ${device.tablet} {
          left: 166px;
        }
    }
  }

  @media ${device.desktopXXL} {
    h2 {
      width: 400px;
    }
  }
`

export const CardItem = styled.div`
  min-height: 268px !important;
  background-color: ${gray[400]};
  padding: 32px 24px;

  @media ${device.tablet} {
    min-height: 100% !important;
  }
`
