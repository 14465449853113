import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const CreditOnTimeSection = styled.section`
  background-color: #f6f6f5;
  
  button {
    text-transform: none;
    @media ${device.mobile} {
      width: 100%;
    }
    @media ${device.tablet} {
      width: 456px!important;
      margin: 0 auto;
    }
    @media ${device.desktopLG} {
      max-width: 376px!important;
    }
  }
`
