import { laranja, orange, white } from 'src/styles/colors'
import styled, { css } from 'styled-components'

type ButtonCTAProps = {
  isOrange?: boolean;
};

const sharedStyles = css<ButtonCTAProps>`
  background: ${(props: ButtonCTAProps) => (props.isOrange ? orange.extra : laranja.terra)};
  padding: 15px 24px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.94px;
  text-align: center;
  color: ${white};
  &:hover{
    color: #d9d9d9;
  }
`

export const Button = styled.button<ButtonCTAProps>`
  ${sharedStyles}
  width: 100%;
`

export const LinkButton = styled.a<ButtonCTAProps>`
  ${sharedStyles}
  display: block;
`
