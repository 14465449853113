
import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'

export const Container = styled.section`
  background-color: #f6f6f5;

  .contentbox{
    flex-direction: column-reverse;
  }

  @media ${device.tablet} {
    .contentbox{
      flex-direction: row;
    }
  }
`
