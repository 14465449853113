import React from 'react'
import VerticalCarousel from 'src/components/UI/Carousels/VerticalCarousel'
import { CardItem, DebitAndCreditFunctionSection } from './style'
import { IVerticalCarrouselItem } from './types'
import DebitAndCreditFunctionJSON from '../../assets/data/DebitAndCreditFunction.json'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import IcCreditCard from '@interco/icons/core/finance/ic_credit_card'
import IcInterLoop from '@interco/icons/core/brands/ic_inter_loop'
import IcRotateMoney from '@interco/icons/core/finance/ic_rotate_money'
import IcCreditCardNfc from '@interco/icons/core/finance/ic_credit_card_nfc'
import IcCreditCardAdd from '@interco/icons/core/finance/ic_credit_card_add'

const SwitchIcon = ({ name }: {name: string}) => {
  switch (name) {
    case 'ic_credit_card':
      return <IcCreditCard height={30} width={30} color='#FF7A00' />
    case 'ic_inter_loop':
      return <IcInterLoop height={30} width={30} color='#FF7A00' />
    case 'ic_rotate_money':
      return <IcRotateMoney height={30} width={30} color='#FF7A00' />
    case 'ic_credit_card_nfc':
      return <IcCreditCardNfc height={30} width={30} color='#FF7A00' />
    case 'ic_credit_card_add':
      return <IcCreditCardAdd height={30} width={30} color='#FF7A00' />
    default:
      return null
  }
}

const DebitAndCreditFunction = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <DebitAndCreditFunctionSection id='sem-anuidade'>
      <div className='container py-5'>
        <div className='row align-items-center'>
          <div className='col-12 col-md-6 col-xl-6'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500 mb-5 mb-md-5'>
              Cartão PJ com função débito e crédito? Tem!
            </h2>
            <div className='content-info'>
              <p className='fs-14 fs-lg-18 lh-18 lh-lg-22 text-grayscale--400'>
                Cartão virtual? Tem!
              </p>
              <p className='fs-14 fs-lg-18 lh-18 lh-lg-22 text-grayscale--400'>
                Cartão de crédito internacional? Tem também!
              </p>
              <p className='fs-14 fs-lg-18 lh-18 lh-lg-22 text-grayscale--400'>
                Lembrando que o limite e a liberação de crédito estão sujeitos a análise.
              </p>
            </div>
          </div>
          <div className='col-12 col-md-6 offset-lg-0 col-xl-6 offset-xl-0 mt-4'>
            <VerticalCarousel
              sm={{
                verticalItems: 1,
                horizontalItems: 1,
                partialVisibilityGutter: 50,
              }}
              md={{
                verticalItems: 3,
                horizontalItems: 2,
              }}
              lg={{
                verticalItems: 3,
                horizontalItems: 2,
              }}
              xl={{
                verticalItems: 3,
                horizontalItems: 2,
              }}
              removeArrowOnDeviceType={[ 'tablet' ]}
              containerClass='carousel-advantages'
            >
              {DebitAndCreditFunctionJSON?.map((item: IVerticalCarrouselItem) => (
                <CardItem className='bg-gray rounded-3 mb-3 mr-3 card-advantages' key={item.icon}>
                  <SwitchIcon name={item?.icon} />

                  {item?.hasLink ? (
                    <p
                      className='fs-14 fs-xl-16 lh-16 lh-xl-20 text-grayscale--500 fw-600 mt-4'
                      dangerouslySetInnerHTML={{ __html: item.description }}
                      onClick={() => {
                        sendDatalayerEvent({
                          section: 'dobra_03',
                          section_name: 'Cartão PJ com função débito e crédito? Tem!',
                          element_action: 'click button',
                          element_name: `Saiba como + ${item.description}` || 'null',
                          redirect_url: item.hasLink,
                        })
                      }}
                    />
                  ) : (
                    <p
                      className='fs-14 fs-xl-16 lh-16 lh-xl-20 text-grayscale--500 fw-600 mt-4'
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    />
                  )}
                </CardItem>
              ))}
            </VerticalCarousel>
          </div>
        </div>
      </div>
    </DebitAndCreditFunctionSection>
  )
}

export default DebitAndCreditFunction
