import React from 'react'
import * as S from './style'

const PracticalitySection = () => {
  return (
    <S.Container id='praticidade'>
      <div className='container py-5'>
        <div className='row align-items-center'>
          <div className='col-12 col-md-8 col-lg-6'>
            <h2 className='fs-24 fs-md-24 fs-md-32 fs-xl-40 lh-30 lh-md-40 lh-xl-50 text-grayscale--500 mb-4'>
              Praticidade, mas pode chamar de cartão empresarial virtual
            </h2>
            <p className='fs-16 lh-20 fs-lg-18 lh-lg-22 text-grayscale--400 mb-4'>
              Faça suas compras online com rapidez e segurança usando o cartão virtual do seu negócio, com funções de débito e crédito.
            </p>
          </div>
          <div className='col-12 col-md-4 col-lg-5 offset-lg-1 mt-4 mt-md-0 d-none d-md-block'>
            <img
              src='https://central-imagens.bancointer.com.br/images-without-small-versions/dobra7-cartaopj-loop/image.webp'
              alt='Super App Inter Empresas'
              className='w-100'
            />
          </div>
        </div>
      </div>
    </S.Container>
  )
}

export default PracticalitySection
